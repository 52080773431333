<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "AppLayout",
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
}

:root {
  --primary: #61efe7;
  --text: #263238;
  --text-light: #909090;
}

body {
  overflow-y: hidden !important;
}

/* show border around full height container */

.btn.btn-outline-dark {
  border: 2px solid #32cfb0;
  color: white;
  font-weight: normal;
  border-radius: 0;
}

button:focus,
button:active {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel {
  border: #ff3d3d 1px solid !important;
  color: #ff3d3d !important;
  padding: 0.625em 3.0999999999999996em;
}

.swal2-styled.swal2-confirm {
  border: #2eaea7 1px solid !important;
  color: #2eaea7 !important;
  padding: 0.625em 3.0999999999999996em;
}

.swal2-styled.swal2-cancel:hover {
  background-color: #ff3d3d !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #2eaea7 !important;
  color: #fff !important;
}

.swal2-popup {
  font-family: "Poppins", sans-serif !important;
}

.btn.btn-outline-dark:hover,
.btn.btn-outline-dark:active,
.btn.btn-outline-dark:focus,
.btn.btn-outline-dark:visited {
  background-color: #222222;
  border-color: #222222;
  outline: unset;
}

.btn-success-custom {
  background: #32cfb0;
  color: white;
  font-size: 13px;
  padding: 10px 26px;
  border-radius: 0;
}

.btn-success-custom:hover:not(:disabled),
.btn-success-custom:focus:not(:disabled),
.btn-success-custom:active:not(:disabled) {
  background-color: #222222;
  color: white;
}

.btn-success-custom:disabled,
.btn-success-custom:disabled:hover {
  background: #636363 !important;
  color: white;
}

/* Selec2 */

.select2-container .select2-selection--single {
  height: 42px;
}

.btn-add {
  background-color: #3cb778 !important;
  color: #fff !important;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  border-radius: 5px;
  text-decoration: none !important;
  font-size: 13px !important;
  border: 1px solid #3cb778;
}

.btn-red {
  background-color: #ff5252 !important;
  color: #fff !important;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  border-radius: 5px;
  text-decoration: none !important;
  font-size: 15px !important;
  border: 1px solid #ff5252;
}

/* .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: linear-gradient(103.2deg, #6EC0FE 0%, #4DF3DD 100%) !important;
  color: #fff !important;
} */

.green-bedge {
  background-color: #d7ffea;
  color: #3cb778;
  text-align: center;
  padding: 7px 8px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
}

.red-bedge {
  background-color: #ffd7d7;
  color: #ff3d3d;
  text-align: center;
  padding: 7px 8px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
}

/* Table */

.dataTables_filter {
  display: none;
}

.dataTables_length {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

input:disabled {
  background: #f3f4f4 !important;
}

textarea:read-only {
  background: #f3f4f4 !important;
}

.card {
  margin-bottom: 0px !important;
}

.dp__input {
  padding: 8px 51px !important;
}

.dp__input {
  border: 1px solid #eceff1 !important;
}

.dp__main {
  width: 100%;
}

.main-content .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.modal .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  background: #68caf8 !important;
  border: none;
}

.main-content
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled,
.modal
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  background: #fff !important;
  color: #68caf8 !important;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 30px !important;
}

.dp__icon {
  border: 1px solid #eceff1 !important;
  border-radius: 0.25rem 0px 0px 0.25rem !important;
}

.dp__input_icons {
  padding: 12px 15px !important;
}

.tooltip .arrow {
  display: none !important;
}

.tooltip .tooltip-inner {
  background-color: #68caf8;
}

.select2-container--default .select2-results__option--highlighted {
  background-color: #eceff1 !important;
  color: #000 !important;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background: #f3f4f4 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 12px !important;
  padding-right: 40px !important;
}

.bank-options
  .select2-container--default
  .selection
  .select2-selection--single {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.select2-dropdown {
  border: 1px solid #eceff1 !important;
}

.row .col-6 .form-group .container .beforeUpload .icon {
  width: 65px;
}

.imgsPreview .imageHolder .plus {
  line-height: 28px !important;
}
</style>
