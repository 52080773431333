import { createRouter, createWebHistory } from "vue-router";
import AppLayout from "../App.vue";
import DashboardLayout from "../Dashboard.vue";
import { cksClient } from "../helper";
const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "login",
        component: () => import("../views/Login.vue"),
      },

      {
        path: "/verifikasi",
        name: "verifikasi",
        component: () => import("../views/Verifikasi.vue"),
      },

      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("../views/ForgotPassword.vue"),
      },

      {
        path: "/invite/:token",
        name: "invite",
        component: () => import("../views/Invite.vue"),
      },

      {
        path: "/new-passwords",
        name: "newPassword",
        component: () => import("../views/NewPassword.vue"),
      },
    ],
  },

  {
    path: "/dashboard",
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      var token = cksClient().get("_authToken");
      if (!token) {
        localStorage.setItem("unlogin", true);
        next("/");
      }

      next();
    },

    children: [
      {
        path: "/data-log-aktivitas",
        name: "DataLogAktivitas",
        component: () => import("../views/Dashboard/Log_Aktivitas/DataLog.vue"),
      },
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/Dashboard/Dashboard.vue"),
      },
      {
        path: "/data-akun-perkiraan",
        name: "DataEstimatedAccount",
        component: () =>
          import(
            "../views/Dashboard/EstimatedAccount/DataEstimatedAccount.vue"
          ),
      },
      {
        path: "/data-company",
        name: "dataCompany",
        component: () => import("../views/Dashboard/DataCompany.vue"),
      },

      {
        path: "/data-departement",
        name: "dataDepartement",
        component: () =>
          import("../views/Dashboard/Department/DataDepartement.vue"),
      },

      {
        path: "/data-departement/edit/:id",
        name: "editDepartement",
        component: () =>
          import("../views/Dashboard/Department/AddDepartement.vue"),
      },
      {
        path: "/data-departement/tambah",
        name: "tambahDepartement",
        component: () =>
          import("../views/Dashboard/Department/AddDepartement.vue"),
      },

      {
        path: "/data-departement/:id_divisi/:id_item/penomoran",
        name: "NumberingDetailsDepartement",
        component: () =>
          import("../views/Dashboard/Department/NumberingDetails.vue"),
      },
      {
        path: "/data-departement/:id_divisi/tambah-penomoran/:id_tipe?",
        name: "addNumberingDepartement",
        component: () =>
          import("../views/Dashboard/Department/NumberingDetails.vue"),
      },
      {
        path: "/data-gudang",
        name: "dataWerehouse",
        component: () =>
          import("../views/Dashboard/Werehouse/DataWerehouse.vue"),
      },
      {
        path: "/data-gudang/edit/:id",
        name: "editWerehouse",
        component: () =>
          import("../views/Dashboard/Werehouse/AddWerehouse.vue"),
      },
      {
        path: "/data-gudang/tambah",
        name: "AddWerehouse",
        component: () =>
          import("../views/Dashboard/Werehouse/AddWerehouse.vue"),
      },

      {
        path: "/data-sister-company",
        name: "dataSisterCompany",
        component: () =>
          import("../views/Dashboard/SisterCompany/DataSisterComp.vue"),
      },
      {
        path: "/data-sister-company/:id/edit",
        name: "EditSisterCompany",
        component: () =>
          import("../views/Dashboard/SisterCompany/AddSisterCompany.vue"),
      },
      {
        path: "/data-sister-company/tambah",
        name: "AddSisterCompany",
        component: () =>
          import("../views/Dashboard/SisterCompany/AddSisterCompany.vue"),
      },

      {
        path: "/data-jabatan",
        name: "DataJabatan",
        component: () => import("../views/Dashboard/Position/DataPosition.vue"),
      },

      {
        path: "/data-status-karyawan",
        name: "DataEmployeeStatus",
        component: () =>
          import("../views/Dashboard/Employee_Status/DataEmployeeStatus.vue"),
      },

      {
        path: "/data-client",
        name: "DataClient",
        component: () => import("../views/Dashboard/Client/DataClient.vue"),
      },

      {
        path: "/data-client/tambah",
        name: "InsertClient",
        component: () => import("../views/Dashboard/Client/InsertClient.vue"),
      },
      {
        path: "/data-client-kategori",
        name: "DataClientCategory",
        component: () =>
          import("../views/Dashboard/Client/DataClientCategory.vue"),
      },

      {
        path: "/data-client/:id/detail",
        name: "DataClientDetail",
        component: () => import("../views/Dashboard/Client/DetailClient.vue"),
      },

      {
        path: "/data-client/data-contact/:id_client/detail/:id_contact?",
        name: "DetailContact",
        component: () =>
          import("../views/Dashboard/ClientContact/DetailContact.vue"),
      },

      {
        path: "/data-vendor",
        name: "DataVendor",
        component: () => import("../views/Dashboard/Vendor/DataVendor.vue"),
      },

      {
        path: "/data-vendor-kategori",
        name: "DataVendorKategori",
        component: () => import("../views/Dashboard/Vendor/VendorCategory.vue"),
      },

      {
        path: "/data-vendor/:id/detail",
        name: "DetailVendor",
        component: () => import("../views/Dashboard/Vendor/DetailVendor.vue"),
      },

      {
        path: "/data-vendor/tambah",
        name: "InsertVendor",
        component: () => import("../views/Dashboard/Vendor/InsertVendor.vue"),
      },

      {
        path: "/data-vendor/data-contact",
        name: "ContactVendor",
        component: () =>
          import("../views/Dashboard/Vendor/ContactVendor/VendorContact.vue"),
      },

      {
        path: "/data-vendor/data-contact/:id_vendor/detail/:id_contact?",
        name: "ContactVendorDetail",
        component: () =>
          import("../views/Dashboard/Vendor/ContactVendor/DetailContact.vue"),
      },

      {
        path: "/data-vendor/data-contact/tambah",
        name: "InsertContactVendor",
        component: () =>
          import("../views/Dashboard/Vendor/ContactVendor/InsertContact.vue"),
      },

      {
        path: "/data-karyawan",
        name: "DataEmployee",
        component: () => import("../views/Dashboard/Employee/DataEmployee.vue"),
      },

      {
        path: "/data-freelance",
        name: "DataFreelance",
        component: () => import("../views/Dashboard/Employee/DataFreelance.vue"),
      },


      {
        path: "/data-karyawan/:id/detail",
        name: "DetailEmployee",
        component: () =>
          import("../views/Dashboard/Employee/DetailEmployee.vue"),
      },

      {
        path: "/data-freelance/:id/detail",
        name: "DetailFreelance",
        component: () =>
          import("../views/Dashboard/Employee/InsertFreelance.vue"),
      },

      {
        path: "/data-karyawan/:id/keluarga/:nomor?",
        name: "InsertFamily",
        component: () => import("../views/Dashboard/Employee/InsertFamily.vue"),
      },

      {
        path: "/data-karyawan/:id/pendidikan/:nomor?",
        name: "InsertEducation",
        component: () =>
          import("../views/Dashboard/Employee/InsertEducation.vue"),
      },

      {
        path: "/data-karyawan/:id_karyawan/:id_payroll/payroll",
        name: "DetailPayrollEmployee",
        component: () =>
          import("../views/Dashboard/Employee/DetailPayroll.vue"),
      },

      {
        path: "/data-karyawan/tambah",
        name: "InsertEmployee",
        component: () =>
          import("../views/Dashboard/Employee/InsertEmployee.vue"),
      },

      {
        path: "/data-freelance/tambah",
        name: "InsertFreelance",
        component: () =>
          import("../views/Dashboard/Employee/InsertFreelance.vue"),
      },

      {
        path: "/data-karyawan/departemen",
        name: "DepartementEmployee",
        component: () =>
          import("../views/Dashboard/Employee/DepartementEmployee.vue"),
      },

      {
        path: "/data-karyawan/rekening",
        name: "RekeningEmployee",
        component: () =>
          import("../views/Dashboard/Employee/RekeningEmployee.vue"),
      },

      {
        path: "/data-karyawan/pajak",
        name: "TaxEmployee",
        component: () => import("../views/Dashboard/Employee/TaxEmployee.vue"),
      },

      {
        path: "/data-karyawan/histori",
        name: "PositionHistory",
        component: () =>
          import("../views/Dashboard/Employee/PositionHistory.vue"),
      },

      {
        path: "/data-jurnal-perkiraan",
        name: "EstimatedAccount",
        component: () =>
          import("../views/Dashboard/Jurnal/EstimatedAccount.vue"),
      },

      {
        path: "/data-jurnal-perkiraan/:id/detail",
        name: "DetailEstimatedAccount",
        component: () =>
          import("../views/Dashboard/Jurnal/DetailEstimatedAccount.vue"),
      },

      {
        path: "/data-jurnal-perkiraan/tambah",
        name: "InsertEstimatedAccount",
        component: () =>
          import("../views/Dashboard/Jurnal/InsertEstimatedAccount.vue"),
      },
      {
        path: "/data-jurnal-perkiraan/history",
        name: "HistoryEstimated",
        component: () =>
          import("../views/Dashboard/Jurnal/HistoryEstimated.vue"),
      },

      {
        path: "/data-jurnal-perkiraan/history/detail",
        name: "DetailHistoryEstimated",
        component: () => import("../views/Dashboard/Jurnal/HistoryDetail.vue"),
      },

      {
        path: "/data-jurnal-umum",
        name: "GeneralJournal",
        component: () => import("../views/Dashboard/Jurnal/GeneralJournal.vue"),
      },

      {
        path: "/data-jurnal-umum/:id/detail",
        name: "DetailGeneralJournal",
        component: () =>
          import("../views/Dashboard/Jurnal/DetailGeneralJournal.vue"),
      },

      {
        path: "/data-jurnal-umum/tambah",
        name: "InsertGeneralJournal",
        component: () =>
          import("../views/Dashboard/Jurnal/DetailGeneralJournal.vue"),
      },

      {
        path: "/data-transfer-bank",
        name: "TransferBank",
        component: () => import("../views/Dashboard/Jurnal/GeneralJournal.vue"),
      },

      {
        path: "/data-transfer-bank/:id/detail",
        name: "DetailTransferBank",
        component: () =>
          import("../views/Dashboard/Jurnal/DetailTransferBank.vue"),
      },

      {
        path: "/data-transfer-bank/tambah",
        name: "InsertTransferBank",
        component: () =>
          import("../views/Dashboard/Jurnal/DetailTransferBank.vue"),
      },

      {
        path: "/data-item-penomoran",
        name: "NumberingItems",
        component: () =>
          import("../views/Dashboard/Setting/NumberingItems.vue"),
      },

      {
        path: "/data-item-penomoran/:id/detail",
        name: "DetailNumberingItems",
        component: () =>
          import("../views/Dashboard/Setting/DetailNumberingItems.vue"),
      },

      {
        path: "/data-item-penomoran/tambah",
        name: "InsertNumberingItems",
        component: () =>
          import("../views/Dashboard/Setting/DetailNumberingItems.vue"),
      },

      {
        path: "/data-item-penggajian",
        name: "PayrollItems",
        component: () => import("../views/Dashboard/Setting/PayrollItems.vue"),
      },

      {
        path: "/data-item-penggajian/:id/detail",
        name: "DetailPayrollItems",
        component: () =>
          import("../views/Dashboard/Setting/DetailPayrollItems.vue"),
      },

      {
        path: "/data-item-penggajian/tambah",
        name: "InsertPayrollItems",
        component: () =>
          import("../views/Dashboard/Setting/DetailPayrollItems.vue"),
      },

      {
        path: "/data-income",
        name: "DataIncome",
        component: () => import("../views/Dashboard/Ledger/DataIncome.vue"),
      },

      {
        path: "/data-income/:id/detail",
        name: "DetailIncome",
        component: () => import("../views/Dashboard/Ledger/DetailIncome.vue"),
      },

      {
        path: "/data-income/tambah",
        name: "InsertIncome",
        component: () => import("../views/Dashboard/Ledger/DetailIncome.vue"),
      },

      {
        path: "/data-spending",
        name: "DataSpending",
        component: () => import("../views/Dashboard/Ledger/DataSpending.vue"),
      },

      {
        path: "/data-spending/:id/detail",
        name: "DetailSpending",
        component: () => import("../views/Dashboard/Ledger/DetailSpending.vue"),
      },

      {
        path: "/data-spending/tambah",
        name: "InsertSpending",
        component: () => import("../views/Dashboard/Ledger/DetailSpending.vue"),
      },

      {
        path: "/data-rekonsiliasi",
        name: "DataReconciliation",
        component: () => import("../views/Dashboard/Jurnal/DataReconciliation.vue"),
      },

      {
        path: "/data-project",
        name: "DataProject",
        component: () => import("../views/Dashboard/Project/DataProject.vue"),
      },

      {
        path: "/data-level-project",
        name: "LevelProject",
        component: () => import("../views/Dashboard/Project/LevelProject.vue"),
      },

      {
        path: "/data-kategori-project",
        name: "KategoriProject",
        component: () =>
          import("../views/Dashboard/Project/KategoriProject.vue"),
      },

      {
        path: "/data-project/tambah",
        name: "InsertProject",
        component: () => import("../views/Dashboard/Project/InsertProject.vue"),
      },

      {
        path: "/data-project/:kode/detail",
        name: "DetailProject",
        component: () => import("../views/Dashboard/Project/DetailProject.vue"),
      },

      {
        path: "/data-project/:kode_project/tambah-invoice",
        name: "InvoiceProject",
        component: () => import("../views/Dashboard/Project/CreateInvoice.vue"),
      },

      {
        path: "/data-credit/:kode_project/:id/payment-invoice",
        name: "PaymentInvoiceProject",
        component: () =>
          import("../views/Dashboard/AccountsReceivable/PaymentInvoice.vue"),
      },

      {
        path: "/data-credit/jurnal-balik",
        name: "FlipJournal",
        component: () =>
          import("../views/Dashboard/AccountsReceivable/FlipJournal.vue"),
      },

      {
        path: "/data-project/detail/anggota",
        name: "DetailMember",
        component: () => import("../views/Dashboard/Project/DetailMember.vue"),
      },

      {
        path: "/data-project/:kode/rab/:type?/:nomor?",
        name: "DetailRAB",
        component: () => import("../views/Dashboard/Project/DetailRAB.vue"),
      },

      {
        path: "/data-project/:kode/pengeluaran/:nomor?",
        name: "DetailPengeluaran",
        component: () =>
          import("../views/Dashboard/Project/DetailPengeluaran.vue"),
      },

      {
        path: "/data-project/detail/pembayaran",
        name: "DetailPayment",
        component: () => import("../views/Dashboard/Project/DetailPayment.vue"),
      },

      {
        path: "/data-project/detail/pembayaran/tambah-invoice",
        name: "CreateInvoice",
        component: () => import("../views/Dashboard/Project/CreateInvoice.vue"),
      },

      {
        path: "/data-credit",
        name: "DataCredit",
        component: () =>
          import(
            "../views/Dashboard/AccountsReceivable/DataAccountsReceivable.vue"
          ),
      },

      {
        path: "/data-credit/tambah",
        name: "InsertCredit",
        component: () =>
          import(
            "../views/Dashboard/AccountsReceivable/DetailAccountsReceivable.vue"
          ),
      },

      {
        path: "/data-credit/:id/detail",
        name: "DetailCredit",
        component: () =>
          import(
            "../views/Dashboard/AccountsReceivable/DetailAccountsReceivable.vue"
          ),
      },

      {
        path: "/data-credit/:id/tambah-invoice",
        name: "CreateInvoiceAccountReceivable",
        component: () =>
          import("../views/Dashboard/AccountsReceivable/CreateInvoice.vue"),
      },

      {
        path: "/data-credit/:id/payment-invoice",
        name: "PayemntInvoiceAccountReceivable",
        component: () =>
          import("../views/Dashboard/AccountsReceivable/PaymentInvoice.vue"),
      },

      {
        path: "/data-hutang",
        name: "DataAccountPayable",
        component: () =>
          import("../views/Dashboard/AccountPayable/DataAccountPayable.vue"),
      },

      {
        path: "/data-hutang/flip-journal",
        name: "FlipJournalPayble",
        component: () =>
          import("../views/Dashboard/AccountPayable/FlipJournalPayble.vue"),
      },

      {
        path: "/data-hutang/:id/detail",
        name: "DetailAccountPayable",
        component: () =>
          import("../views/Dashboard/AccountPayable/DetailAccountPayable.vue"),
      },

      {
        path: "/data-hutang/tambah",
        name: "InsertAccountPayable",
        component: () =>
          import("../views/Dashboard/AccountPayable/DetailAccountPayable.vue"),
      },

      {
        path: "/data-hutang/:id/tambah-invoice",
        name: "CreateInovice",
        component: () =>
          import("../views/Dashboard/AccountPayable/CreateInovice.vue"),
      },

      {
        path: "/data-hutang/:id/payment-invoice",
        name: "PaymentInovice",
        component: () =>
          import("../views/Dashboard/AccountPayable/PaymentInovice.vue"),
      },

      {
        path: "/data-asset",
        name: "DataAsset",
        component: () => import("../views/Dashboard/Asset/DataAsset.vue"),
      },

      {
        path: "/data-asset-check",
        name: "AssetCheck",
        component: () =>
          import("../views/Dashboard/Asset/Check/AssetCheck.vue"),
      },

      {
        path: "/data-asset-check/tambah",
        name: "InsertAssetCheck",
        component: () =>
          import("../views/Dashboard/Asset/Check/InsertAssetCheck.vue"),
      },

      {
        path: "/data-asset-check/:kode/edit",
        name: "EditAssetCheck",
        component: () =>
          import("../views/Dashboard/Asset/Check/InsertAssetCheck.vue"),
      },

      {
        path: "/data-asset-check/:kode/detail",
        name: "DetailAssetCheck",
        component: () =>
          import("../views/Dashboard/Asset/Check/DetailAssetCheck.vue"),
      },

      {
        path: "/data-asset-check/:kode/tambah-hasil",
        name: "InsertResultAssetCheck",
        component: () =>
          import("../views/Dashboard/Asset/Check/InsertResultAssetCheck.vue"),
      },

      {
        path: "/data-asset/:id/detail",
        name: "DetailAsset",
        component: () => import("../views/Dashboard/Asset/DetailAsset.vue"),
      },
      {
        path: "/data-asset/tambah",
        name: "InsertAsset",
        component: () => import("../views/Dashboard/Asset/DetailAsset.vue"),
      },
      {
        path: "/data-asset-kategori",
        name: "AssetCategory",
        component: () => import("../views/Dashboard/Asset/AssetCategory.vue"),
      },

      {
        path: "/data-asset-selling",
        name: "AssetSelling",
        component: () => import("../views/Dashboard/Asset/Selling/List.vue"),
      },

      {
        path: "/data-asset-selling/tambah",
        name: "InsertAssetSelling",
        component: () =>
          import("../views/Dashboard/Asset/Selling/InsertSelling.vue"),
      },

      {
        path: "/data-asset-selling/:id/detail",
        name: "DetailAssetSelling",
        component: () =>
          import("../views/Dashboard/Asset/Selling/InsertSelling.vue"),
      },

      {
        path: "/data-kasbon",
        name: "DataEmployeeCash",
        component: () =>
          import("../views/Dashboard/EmployeeCash/DataEmployeeCash.vue"),
      },

      {
        path: "/data-kasbon/jurnal-kembali",
        name: "JournalBack",
        component: () =>
          import("../views/Dashboard/EmployeeCash/JournalBack.vue"),
      },

      {
        path: "/data-kasbon/:id/detail",
        name: "DetailEmployeeCash",
        component: () =>
          import("../views/Dashboard/EmployeeCash/DetailEmployeeCash.vue"),
      },

      {
        path: "/data-kasbon/tambah",
        name: "InsertEmployeeCash",
        component: () =>
          import("../views/Dashboard/EmployeeCash/InsertEmployeeCash.vue"),
      },

      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/Dashboard/Profile/Profile.vue"),
      },
      {
        path: "/data-barang",
        name: "DataGoods",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/DataGoods.vue"),
      },

      {
        path: "/data-barang/pengeluaran",
        name: "Expenditures",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/Expenditures.vue"),
      },

      {
        path: "/data-barang-kategori",
        name: "DataGoodsCategory",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/DataGoodsCategory.vue"),
      },

      {
        path: "/data-barang/:kode/detail",
        name: "DetailGoods",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/DetailGoods.vue"),
      },

      {
        path: "/data-barang/tambah",
        name: "InsertGoods",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/InsertGoods.vue"),
      },

      {
        path: "/data-barang/detail/tambah-po",
        name: "CreatePO",
        component: () =>
          import("../views/Dashboard/Inventory/Goods/CreatePO.vue"),
      },

      {
        path: "/data-penerimaan",
        name: "DataReception",
        component: () =>
          import("../views/Dashboard/Inventory/Reception/DataReception.vue"),
      },

      {
        path: "/data-penerimaan/:kode/detail",
        name: "DetailReception",
        component: () =>
          import("../views/Dashboard/Inventory/Reception/DetailReception.vue"),
      },

      {
        path: "/data-penerimaan/tambah/:kode_po?",
        name: "InsertReception",
        component: () =>
          import("../views/Dashboard/Inventory/Reception/DetailReception.vue"),
      },

      {
        path: "/data-opname",
        name: "DataOpname",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Order_Opname/DataOpname.vue"
          ),
      },

      {
        path: "/data-opname/:kode/detail",
        name: "DetailOpname",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Order_Opname/DetailOpname.vue"
          ),
      },

      {
        path: "/data-opname/:kode/hasil",
        name: "StockResult",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Order_Opname/StockResult.vue"
          ),
      },

      {
        path: "/data-opname/:kode/edit",
        name: "EditOpname",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Order_Opname/InsertOpname.vue"
          ),
      },

      {
        path: "/data-opname/tambah",
        name: "InsertOpname",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Order_Opname/InsertOpname.vue"
          ),
      },

      {
        path: "/data-stock",
        name: "DataStock",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Stock_Result/DataStock.vue"
          ),
      },

      {
        path: "/data-stock/detail",
        name: "DetailStock",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Stock_Result/DetailStock.vue"
          ),
      },

      {
        path: "/data-stock/tambah",
        name: "InsertStock",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Opname/Stock_Result/InsertStock.vue"
          ),
      },

      {
        path: "/data-po/",
        name: "DataPO",
        component: () =>
          import("../views/Dashboard/Inventory/Purchases_Order/DataPO.vue"),
      },

      {
        path: "/data-po/:kode_po/payment-order/:id_hutang?",
        name: "PaymentOrder",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Purchases_Order/PaymentOrder.vue"
          ),
      },

      {
        path: "/data-po/:kode/detail",
        name: "DetailPO",
        component: () =>
          import("../views/Dashboard/Inventory/Purchases_Order/DetailPO.vue"),
      },

      {
        path: "/data-PO/tambah/:kode_barang?",
        name: "InsertPO",
        component: () =>
          import("../views/Dashboard/Inventory/Purchases_Order/InsertPO.vue"),
      },

      //SUPLY
      {
        path: "/data-supply",
        name: "DataGoodsSupply",
        component: () => import("../views/Dashboard/Stock/Goods/DataGoods.vue"),
      },

      {
        path: "/data-supply/pengeluaran",
        name: "ExpendituresSupply",
        component: () =>
          import("../views/Dashboard/Stock/Goods/Expenditures.vue"),
      },

      {
        path: "/data-supply-kategori",
        name: "DataGoodsCategorySupply",
        component: () =>
          import("../views/Dashboard/Stock/Goods/DataGoodsCategory.vue"),
      },

      {
        path: "/data-supply/:kode/detail",
        name: "DetailGoodsSupply",
        component: () =>
          import("../views/Dashboard/Stock/Goods/DetailGoods.vue"),
      },

      {
        path: "/data-supply/tambah",
        name: "InsertGoodsSupply",
        component: () =>
          import("../views/Dashboard/Stock/Goods/InsertGoods.vue"),
      },

      {
        path: "/data-supply/detail/tambah-po",
        name: "CreatePOSupply",
        component: () => import("../views/Dashboard/Stock/Goods/CreatePO.vue"),
      },

      {
        path: "/data-penerimaan-supply",
        name: "DataReceptionSupply",
        component: () =>
          import("../views/Dashboard/Stock/Reception/DataReception.vue"),
      },

      {
        path: "/data-penerimaan-supply/:kode/detail",
        name: "DetailReceptionSupply",
        component: () =>
          import("../views/Dashboard/Stock/Reception/DetailReception.vue"),
      },

      {
        path: "/data-penerimaan-supply/tambah/:kode_po?",
        name: "InsertReceptionSupply",
        component: () =>
          import("../views/Dashboard/Stock/Reception/DetailReception.vue"),
      },

      {
        path: "/data-opname-supply",
        name: "DataOpnameSupply",
        component: () =>
          import("../views/Dashboard/Stock/Opname/Order_Opname/DataOpname.vue"),
      },

      {
        path: "/data-opname-supply/:kode/detail",
        name: "DetailOpnameSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Order_Opname/DetailOpname.vue"
          ),
      },

      {
        path: "/data-opname-supply/:kode/hasil",
        name: "StockResultSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Order_Opname/StockResult.vue"
          ),
      },

      {
        path: "/data-opname-supply/:kode/edit",
        name: "EditOpnameSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Order_Opname/InsertOpname.vue"
          ),
      },

      {
        path: "/data-opname-supply/tambah",
        name: "InsertOpnameSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Order_Opname/InsertOpname.vue"
          ),
      },

      {
        path: "/data-stock-supply",
        name: "DataStockSupply",
        component: () =>
          import("../views/Dashboard/Stock/Opname/Stock_Result/DataStock.vue"),
      },

      {
        path: "/data-stock-supply/detail",
        name: "DetailStockSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Stock_Result/DetailStock.vue"
          ),
      },

      {
        path: "/data-stock-supply/tambah",
        name: "InsertStockSupply",
        component: () =>
          import(
            "../views/Dashboard/Stock/Opname/Stock_Result/InsertStock.vue"
          ),
      },

      {
        path: "/data-po-supply/",
        name: "DataPOSupply",
        component: () =>
          import("../views/Dashboard/Stock/Purchases_Order/DataPO.vue"),
      },

      {
        path: "/data-po-supply/:kode_po/payment-order/:id_hutang?",
        name: "PaymentOrderSupply",
        component: () =>
          import("../views/Dashboard/Stock/Purchases_Order/PaymentOrder.vue"),
      },

      {
        path: "/data-po-supply/:kode/detail",
        name: "DetailPOSupply",
        component: () =>
          import("../views/Dashboard/Stock/Purchases_Order/DetailPO.vue"),
      },

      {
        path: "/data-PO-supply/tambah/:kode_supply?",
        name: "InsertPOSupply",
        component: () =>
          import("../views/Dashboard/Stock/Purchases_Order/InsertPO.vue"),
      },
      //SUPLY

      //PAYMENT ORDER
      {
        path: "/data-payment",
        name: "DataPaymentOrder",
        component: () =>
          import("../views/Dashboard/PaymentOrder/DataPaymentOrder.vue"),
      },
      {
        path: "/data-payment/:kode/cetak",
        name: "DataPaymentOrderPrint",
        component: () =>
          import("../views/Dashboard/PaymentOrder/PrintPaymentOrder.vue"),
      },
      {
        path: "/data-payment/tambah",
        name: "InsertPaymentOrder",
        component: () =>
          import("../views/Dashboard/PaymentOrder/InsertPaymentOrder.vue"),
      },

      {
        path: "/data-payment/tambah-opex",
        name: "InsertOpex",
        component: () =>
          import("../views/Dashboard/PaymentOrder/InsertOpex.vue"),
      },

      {
        path: "/data-payment/:id_hutang/opex",
        name: "EditOpex",
        component: () =>
          import("../views/Dashboard/PaymentOrder/InsertOpex.vue"),
      },

      {
        path: "/data-payment/:id_hutang/detail/",
        name: "DetailPaymentOrder",
        component: () =>
          import("../views/Dashboard/PaymentOrder/InsertPaymentOrder.vue"),
      },
      //PAYMENT ORDER

      {
        path: "/data-item-synchronize",
        name: "Synchronize",
        component: () => import("../views/Dashboard/Setting/Synchronize.vue"),
      },

      {
        path: "/data-fee-marketing",
        name: "FeeMarketing",
        component: () => import("../views/Dashboard/Setting/FeeMarketing.vue"),
      },

      {
        path: "/data-reimburse",
        name: "DataReimburse",
        component: () =>
          import("../views/Dashboard/Reimburse/DataReimburse.vue"),
      },

      {
        path: "/data-reimburse/:id/detail",
        name: "DetailReimburse",
        component: () =>
          import("../views/Dashboard/Reimburse/DetailReimburse.vue"),
      },

      {
        path: "/data-reimburse/tambah",
        name: "InsertReimburse",
        component: () =>
          import("../views/Dashboard/Reimburse/DetailReimburse.vue"),
      },

      {
        path: "/data-payroll",
        name: "DataPayroll",
        component: () => import("../views/Dashboard/Payroll/DataPayroll.vue"),
      },

      {
        path: "/data-payroll/:id/detail-gaji",
        name: "DetailSalary",
        component: () =>
          import("../views/Dashboard/Payroll/Salary/DetailSalary.vue"),
      },

      {
        path: "/data-payroll/:id/detail-bonus",
        name: "DetailBonus",
        component: () =>
          import("../views/Dashboard/Payroll/Bonus/DetailBonus.vue"),
      },

      {
        path: "/data-payroll/:id/detail-thr",
        name: "DetailTHR",
        component: () => import("../views/Dashboard/Payroll/THR/DetailTHR.vue"),
      },

      {
        path: "/data-payroll/:id/detail-man-power",
        name: "DetailManPower",
        component: () =>
          import("../views/Dashboard/Payroll/ManPower/DetailManPower.vue"),
      },

      {
        path: "/data-payroll/tambah-gaji",
        name: "InsertSalary",
        component: () =>
          import("../views/Dashboard/Payroll/Salary/InsertSalary.vue"),
      },

      {
        path: "/data-payroll/tambah-man-power",
        name: "InsertManPower",
        component: () =>
          import("../views/Dashboard/Payroll/ManPower/InsertManPower.vue"),
      },

      {
        path: "/data-payroll/tambah-bonus",
        name: "InsertBonus",
        component: () =>
          import("../views/Dashboard/Payroll/Bonus/InsertBonus.vue"),
      },

      {
        path: "/data-payroll/tambah-thr",
        name: "InsertTHR",
        component: () => import("../views/Dashboard/Payroll/THR/InsertTHR.vue"),
      },

      {
        path: "/data-payroll/:id/slip-gaji",
        name: "SalarySlip",
        component: () =>
          import("../views/Dashboard/Payroll/Salary/SalarySlip.vue"),
      },

      {
        path: "/data-payroll/:id/slip-bonus",
        name: "BonusSlip",
        component: () =>
          import("../views/Dashboard/Payroll/Bonus/BonusSlip.vue"),
      },

      {
        path: "/data-payroll/:id/slip-thr",
        name: "THRSlip",
        component: () => import("../views/Dashboard/Payroll/THR/THRSlip.vue"),
      },
      {
        path: "/data-payroll/:id/slip-man-power",
        name: "ManPowerSlip",
        component: () =>
          import("../views/Dashboard/Payroll/ManPower/ManPowerSlip.vue"),
      },

      {
        path: "/report",
        name: "ReportIndex",
        component: () => import("../views/Dashboard/Report/Index.vue"),
      },

      {
        path: "/data-laporan-akun-perkiraan",
        name: "ReportEstimatedAccount",
        component: () =>
          import("../views/Dashboard/Report/Ledger/EstimatedAccount.vue"),
      },

      {
        path: "/data-rincian-buku-besar",
        name: "LedgerDetails",
        component: () =>
          import("../views/Dashboard/Report/Ledger/LedgerDetails.vue"),
      },

      {
        path: "/data-ringkasan-buku-besar",
        name: "LedgerSummary",
        component: () =>
          import("../views/Dashboard/Report/Ledger/LedgerSummary.vue"),
      },

      {
        path: "/data-laporan-jurnal-umum",
        name: "GeneralJournalReport",
        component: () =>
          import("../views/Dashboard/Report/Ledger/GeneralJournal.vue"),
      },

      {
        path: "/data-laporan-neraca-standar",
        name: "StandarBalance",
        component: () =>
          import("../views/Dashboard/Report/Balance/StandardBalance.vue"),
      },

      {
        path: "/data-laporan-multi-periode",
        name: "MultiPeriod",
        component: () =>
          import("../views/Dashboard/Report/Balance/MultiPeriod.vue"),
      },

      {
        path: "/data-laporan-neraca-perbandingan",
        name: "ComparasionPeriod",
        component: () =>
          import("../views/Dashboard/Report/Balance/ComparisonPeriod.vue"),
      },

      {
        path: "/data-laba-rugi-standar",
        name: "ProfitLossStandard",
        component: () =>
          import("../views/Dashboard/Report/ProfitLoss/Standard.vue"),
      },

      {
        path: "/data-laba-rugi-multiperiode",
        name: "MultiPeriodProfitLoss",
        component: () =>
          import("../views/Dashboard/Report/ProfitLoss/MultiPeriod.vue"),
      },

      {
        path: "/data-laba-rugi-kuartal",
        name: "QuarterProfitLoss",
        component: () =>
          import("../views/Dashboard/Report/ProfitLoss/Quarter.vue"),
      },

      {
        path: "/data-laba-rugi-perbandingan",
        name: "PeriodComparison",
        component: () =>
          import("../views/Dashboard/Report/ProfitLoss/PeriodComparison.vue"),
      },

      {
        path: "/data-kartu-hutang",
        name: "KartuHutang",
        component: () =>
          import("../views/Dashboard/Report/Summary/KartuHutang.vue"),
      },

      {
        path: "/data-kartu-piutang",
        name: "KartuPiutang",
        component: () =>
          import("../views/Dashboard/Report/Summary/KartuPiutang.vue"),
      },
      {
        path: "/data-summary-piutang",
        name: "SummaryPiutang",
        component: () =>
          import("../views/Dashboard/Report/Summary/SummaryPiutang.vue"),
      },

      {
        path: "/data-summary-hutang",
        name: "SummaryHutang",
        component: () =>
          import("../views/Dashboard/Report/Summary/SummaryHutang.vue"),
      },

      {
        path: "/data-all-jurnal",
        name: "AllJurnal",
        component: () =>
          import("../views/Dashboard/Report/Summary/AllJurnal.vue"),
      },

      {
        path: "/data-mutasi-persediaan",
        name: "MutasiPersediaan",
        component: () =>
          import("../views/Dashboard/Report/Summary/MutasiPersediaan.vue"),
      },

      {
        path: "/data-mutasi-inventory",
        name: "MutasiInventory",
        component: () =>
          import("../views/Dashboard/Report/Summary/MutasiInventory.vue"),
      },
      {
        path: "/data-laba-rugi-project",
        name: "ProfitLossProject",
        component: () =>
          import("../views/Dashboard/Report/ProfitLoss/ProfitLossProject.vue"),
      },

      {
        path: "/report-detail-project",
        name: "ReportDetailProject",
        component: () =>
          import("../views/Dashboard/Report/Summary/ReportDetailProject.vue"),
      },
      {
        path: "/report-ration-bulanan",
        name: "RatioMonthly",
        component: () =>
          import("../views/Dashboard/Report/Ratio/ReportRatio.vue"),
      },

      {
        path: "/report-ration-tahunan",
        name: "RatioYearly",
        component: () =>
          import("../views/Dashboard/Report/Ratio/ReportRatio.vue"),
      },

      {
        path: "/cash-flow/standard",
        name: "CashFlowStandard",
        component: () =>
          import("../views/Dashboard/Report/CashFlow/Standard.vue"),
      },

      {
        path: "/capital-change/standard",
        name: "CapitalChange",
        component: () =>
          import("../views/Dashboard/Report/CapitalChange/Standard.vue"),
      },

      {
        path: "/error401",
        name: "Error401",
        component: () => import("../views/Dashboard/ErrorPage/Error401.vue"),
      },

      {
        path: "/error400",
        name: "Error400",
        component: () => import("../views/Dashboard/ErrorPage/Error400.vue"),
      },

      {
        path: "/error403",
        name: "Error403",
        component: () => import("../views/Dashboard/ErrorPage/Error403.vue"),
      },
      {
        path: "/error404",
        name: "Error404",
        component: () => import("../views/Dashboard/ErrorPage/Error404.vue"),
      },
      {
        path: "/error405",
        name: "Error405",
        component: () => import("../views/Dashboard/ErrorPage/Error405.vue"),
      },

      {
        path: "/error408",
        name: "Error408",
        component: () => import("../views/Dashboard/ErrorPage/Error408.vue"),
      },

      {
        path: "/error500",
        name: "Error500",
        component: () => import("../views/Dashboard/ErrorPage/Error500.vue"),
      },
      {
        path: "/error502",
        name: "Error502",
        component: () => import("../views/Dashboard/ErrorPage/Error502.vue"),
      },
      {
        path: "/error503",
        name: "Error503",
        component: () => import("../views/Dashboard/ErrorPage/Error503.vue"),
      },
      {
        path: "/error504",
        name: "Error504",
        component: () => import("../views/Dashboard/ErrorPage/Error504.vue"),
      },

      {
        path: "/error505",
        name: "Error505",
        component: () => import("../views/Dashboard/ErrorPage/Error505.vue"),
      },

      {
        path: "/maintenance",
        name: "maintenance",
        component: () => import("../views/Dashboard/ErrorPage/Maintenance.vue"),
      },
      //pengeluaran inventory
      {
        path: "/data-pengeluaran-inventory",
        name: "DataInventorySpending",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Expand/DataInventorySpending.vue"
          ),
      },
      {
        path: "/data-pengeluaran-inventory/tambah",
        name: "InsertInventorySpending",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Expand/InsertInventorySpending.vue"
          ),
      },
      {
        path: "/data-pengeluaran-inventory/:id/detail",
        name: "DetailInventorySpending",
        component: () =>
          import(
            "../views/Dashboard/Inventory/Expand/InsertInventorySpending.vue"
          ),
      },

      //pengeluaran persediaan
      {
        path: "/data-pengeluaran-persediaan",
        name: "DataStockSpending",
        component: () =>
          import(
            "../views/Dashboard/Stock/Expand/DataInventorySpending.vue"
          ),
      },
      {
        path: "/data-pengeluaran-persediaan/tambah",
        name: "InsertStockSpending",
        component: () =>
          import(
            "../views/Dashboard/Stock/Expand/InsertInventorySpending.vue"
          ),
      },
      {
        path: "/data-pengeluaran-persediaan/:id/detail",
        name: "DetailStockSpending",
        component: () =>
          import(
            "../views/Dashboard/Stock/Expand/InsertInventorySpending.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
