import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import LoadScript from "vue-plugin-load-script";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "vue-skeletor/dist/vue-skeletor.css";

import "vue3-date-time-picker/dist/main.css";

import VueTableLite from "vue3-table-lite";

import VCalendar from "v-calendar";

import $ from "jquery";

import excel from "vue-excel-export";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const app = createApp(App);

app.use(VueTableLite);
app.use(LoadScript);
app.use($);
app.use(router);
app.use(VueSweetalert2);
app.use(VCalendar);
app.use(excel);
app.mount("#app");
