const API = {
  LOGIN: "/user/login",
  FORGOT_PASSWORD: "/user/forgot_password",
  CHECK_OTP: "/user/check_otp",
  UPDATE_PASSWORD: "/user/update_password",
  REFRESH_TOKEN: "/user/refresh-token",
  JABATAN: {
    LIST: "/master/jabatan",
    SAVE: "/master/jabatan/save",
    CHECK: "/master/jabatan/cek-kode"
  },
  GUDANG: {
    LIST: "/company/gudang",
    GET: "/company/gudang/getdata",
    SAVE: "/company/gudang/save",
  },
  MASTER: {
    PROVINSI: "/master/list_provinsi",
    KABUPATEN: "/master/list_kabupaten",
    KECAMATAN: "/master/list_kecamatan",
    DIVISI: "/master/divisi/all",
    TRANSAKSI_TIPE: "/master/transaksi-tipe",
    BANK: "/master/bank",
    JENJANG_PENDIDIKAN: "/master/jenjang-pendidikan",
    STATUS_PERNIKAHAN: "/master/status_pernikahan",
    SATUAN_BARANG: "/master/satuan",
    PERIODE_AKUNTANSI: '/master/periode-akuntansi'
  },
  STATUS_EMPLOYEE: {
    LIST: "/master/status-karyawan",
    SAVE: "/master/status-karyawan/save",
  },
  DEPARTEMENT: {
    LIST: "/master/divisi",
    SAVE: "/master/divisi/save",
    GET: "/master/divisi/getdata",
    PENOMORAN: "/company/setting/penomoran-divisi",
    PENOMORAN_GET: "/company/setting/penomoran-divisi/get",
    PENOMORAN_SAVE: "/company/setting/penomoran-divisi/save",
  },
  COMPANY: {
    SETTING: {
      LIST: "/company/setting/",
      SAVE: "/company/setting/save",
      GET: "/company/setting/getdata",
    },
    REKENING: {
      LIST: '/company/rekening',
    },
    LIST: "/company",
    GET: "/company/getdata",
    CHECK_KODE: '/company/cek-code',
    SAVE: "/company/save",
    ACCESS: {
      LIST: "/company/access",
    },
  },
  CLIENT: {
    KATEGORI: {
      ALL: "/client/kategori/all",
      LIST: "/client/kategori",
      SAVE: "/client/kategori/save",
    },
    KONTAK: {
      SAVE: "/client/contact/save",
      GET: "/client/contact/getdata",
    },
    GET: "/client/getdata",
    LIST: "/client",
    SAVE: "/client/save",
  },
  VENDOR: {
    KATEGORI: {
      ALL: "/vendor/kategori/all",
      LIST: "/vendor/kategori",
      CHECK: "/vendor/kategori/cek-kode",
      SAVE: "/vendor/kategori/save",
    },
    KONTAK: {
      LIST: "/vendor/contact/list",
      SAVE: "/vendor/contact/save",
      GET: "/vendor/contact/getdata",
    },
    GET: "/vendor/getdata",
    LIST: "/vendor",
    SAVE: "/vendor/save",
    DOWNLOAD_FORMAT: '/vendor/download-format'
  },
  PAYROLL: {
    ITEM: {
      LIST: "/payroll/item",
      GET: "/payroll/item/getdata",
      SAVE: "/payroll/item/save",
    },
    KATEGORI: {
      LIST: "/payroll/kategori",
    },
    LIST: "/payroll",
    PRA_SAVE: "/payroll/prasave",
    SAVE: "/payroll/save",
    PREVIOUS_ITEM: "/payroll/previous",
    CANCEL: "/payroll/cancel",
    GET: "/payroll/detail",
    GET_SLIP: "/payroll/detail/slip",
    PAYMENT: "/payroll/payment",
    PROJECT: "/payroll/project",
    PROJECT_MANPOWER: "/payroll/project/man-power",
    EXPORT: "/payroll/export"
  },
  AKUN_PERKIRAAN: {
    LIST: "/bukubesar/akunperkiraan",
    SAVE: "/bukubesar/akunperkiraan/save",
    GET: "/bukubesar/akunperkiraan/getdata",
    TIPE: {
      LIST: "/bukubesar/akunperkiraan/gettipe",
    },
    DEFAULT: '/bukubesar/akunperkiraan/default'
  },
  PEMASUKAN: {
    LIST: "/bukubesar/transaksi/pemasukan",
    SAVE: "/bukubesar/transaksi/pemasukan/save",
    GET: "/bukubesar/transaksi/pemasukan/getdata",
  },
  PENGELUARAN: {
    LIST: "/bukubesar/transaksi/pengeluaran",
    SAVE: "/bukubesar/transaksi/pengeluaran/save",
    GET: "/bukubesar/transaksi/pengeluaran/getdata",
  },
  JURNAL_UMUM: {
    LIST: "/bukubesar/transaksi/jurnal-umum",
    SAVE: "/bukubesar/transaksi/jurnal-umum/save",
    GET: "/bukubesar/transaksi/jurnal-umum/getdata",
  },
  REKONSILIASI: {
    LIST: '/bukubesar/transaksi/recontiliation',
    SAVE: '/bukubesar/transaksi/recontiliation/save'
  },
  PROJECT: {
    LEVEL: {
      LIST: "/project/level",
      SAVE: "/project/level/save",
    },
    KATEGORI: {
      LIST: "/project/kategori",
      SAVE: "/project/kategori/save",
    },
    RAB: {
      LIST: "/project/rab",
      LIST_PARENT: "/project/rab/list/parent",
      GET: "/project/rab/getdata",
      SAVE: "/project/rab/save",
      IMPORT: "/project/rab/import",
      ACC: "/project/rab/acc",
      GROUPING: "/project/rab/grouping"
    },
    PENGELUARAN: {
      SAVE: "/project/pengeluaran/save",
      GET: "/project/pengeluaran/getdata",
    },
    INVOICE: {
      PRE: "/project/invoice/pre",
      SAVE: "/project/invoice/save",
    },
    LIST: "/project",
    SAVE: "/project/save",
    GET: "/project/getData",
    LIST_ANGGOTA: "/project/anggota",
    CANCEL: "/project/cancel-project",
    DONE: "/project/done-project",
    PROJECT_REPORT: '/project/report-download'
  },
  KARYAWAN: {
    LIST: "/karyawan",
    SAVE: "/karyawan/save",
    SAVE_FREELANCE: "/karyawan/save-freelance",
    GET: "/karyawan/getdata",
    RESIGN: "/karyawan/resign/save",
    MASTER: {
      STATUS_LIST: "/karyawan/master/status",
      STATUS_PERNIKAHAN: "/karyawan/master/status-pernikahan",
    },
    TRANSFER: {
      SAVE: "/karyawan/transfer/save",
    },
    PENDIDIKAN: {
      SAVE: "/karyawan/pendidikan/save",
      GET: "/karyawan/pendidikan/getdata",
    },
    KELUARGA: {
      SAVE: "/karyawan/keluarga/save",
      GET: "/karyawan/keluarga/getdata",
    },
  },
  HUTANG: {
    LIST: "/hutang",
    SAVE: "/hutang/save",
    SAVE_PEMBAYARAN: "/hutang/bayar",
    SAVE_INVOICE: "/hutang/po/save",
    GET: "/hutang/getdata",
    PRE_INVOICE: "/hutang/po/pre",
    GET_INVOICE: "/hutang/po/getdata",
    CANCEL_INVOICE: "/hutang/po/cancel",
    JURNAL_BACK: '/hutang/jurnal-kembali',
    LIST_PO: '/hutang/po-list',
    PAYMENT_ORDER: {
      SAVE: '/hutang/payment-order/save',
      CETAK: '/hutang/payment-order/cetak',
      UPDATE_CETAK: '/hutang/payment-order/update-cetak',
    },
  },
  PIUTANG: {
    LIST: "/piutang",
    SAVE: "/piutang/save",
    SAVE_PEMBAYARAN: "/piutang/invoice/bayar",
    SAVE_INVOICE: "/piutang/invoice/save",
    GET: "/piutang/getdata",
    PRE_INVOICE: "/piutang/invoice/pre",
    GET_INVOICE: "/piutang/invoice/getdata",
    CANCEL_INVOICE: "/piutang/invoice/cancel",
    JURNAL_BACK: '/piutang/jurnal-kembali',
  },
  KASBON: {
    LIST: "/kasbon",
    SAVE: "/kasbon/save",
    GET: "/kasbon/getdata",
    BAYAR: "/kasbon/bayar",
    JURNAL_BACK: '/kasbon/jurnal-kembali',
  },
  REIMBURSE: {
    LIST: "/reimburse",
    SAVE: "/reimburse/save",
    GET: "/reimburse/getdata",
  },
  ASET: {
    KATEGORI: {
      LIST: "/aset/kategori",
      SAVE: "/aset/kategori/save",
      CHECK: "/aset/kategori/check",
    },
    LIST: "/aset",
    SAVE: "/aset/save",
    GET: "/aset/getdata",
    PENYUSUTAN: {
      LIST: "/aset/metode-penyusutan",
    },
    LOKASI: {
      SAVE: "/aset/lokasi/save",
    },
    PENGECEKAN: {
      LIST: "/aset/pengecekan",
      SAVE: "/aset/pengecekan/save",
      GET: "/aset/pengecekan/getdata",
      PRASAVE: "/aset/pengecekan/prasave",
      SAVE_RESULT: "/aset/pengecekan/hasil/save",
    },
    PEMBAYARAN: {
      SAVE_PEMBAYARAN: "/aset/bayar",
      JURNAL_BACK: '/aset/jurnal-kembali',
    },
    PENJUALAN: {
      LIST: '/aset/penjualan',
      SAVE: '/aset/penjualan/save',
      DETAIL: '/aset/penjualan/getdata',
    }
  },
  BARANG: {
    KATEGORI: {
      LIST: "/barang/kategori",
      SAVE: "/barang/kategori/save",
      CHECK: "/barang/kategori/check",
    },
    STOCK_OPNAME: {
      LIST: "/barang/stock_opname",
      SAVE: "/barang/stock_opname/save",
      GET: "/barang/stock_opname/getdata",
      SAVE_HASIL: "/barang/stock_opname/hasil/save",
      PRA_SAVE: "/barang/stock_opname/prasave",
    },
    PURCHASE_ORDER: {
      LIST: "/barang/po",
      GET: "/barang/po/getdata",
      SAVE: "/barang/po/save",
      PRICE_VENDOR: "/barang/po/harga-vendor",
    },
    RECEPTION: {
      LIST: "/barang/penerimaan",
      GET: "/barang/penerimaan/getdata",
      SAVE: "/barang/penerimaan/save",
      BARANG_LIST: "/barang/penerimaan/barang-detail",
    },
    OUT: {
      LIST: '/barang/pengeluaran',
      SAVE: '/barang/pengeluaran/save',
      SEWA: '/barang/pengeluaran/sewa',
      GET: '/barang/pengeluaran/getdata',
    },
    LIST: '/barang',
    SAVE: '/barang/save',
    GET: '/barang/getdata',
    PRICE: '/barang/getprice',
    LIST_BARANG_GUDANG: '/barang/pengeluaran/prasave',
  },
  DASHBOARD: {
    GET: "/dashboard/get",
    HISTORY: "/dashboard/history",
    CLOSE_BOOK: "/dashboard/closebook",
  },
  FEE_MARKETING: {
    LIST: "/fee-marketing",
    SAVE: "/fee-marketing/save",
  },
  ACCOUNT_SETTING: {
    LIST: "/account-setting",
    SAVEBULK: "/account-setting/savebulk",
  },
  REPORT: {
    LEDGER: {
      RINCIAN_BUKU_BESAR: '/report/buku-besar/rincian-buku-besar',
      LIST_AKUN: '/report/buku-besar/daftar-akun-perkiraan',
      RINGKASAN_BUKU_BESAR: '/report/buku-besar/ringkasan-buku-besar',
      JURNAL_UMUM: '/report/buku-besar/jurnal-umum',
    },
    NERACA: {
      STANDARD: '/report/neraca/standard',
      PERBANDINGAN: '/report/neraca/periode',
      MULTI: '/report/neraca/multi',
    },
    PROFIT_LOSS: {
      STANDARD: '/report/laba-rugi/standard',
      PERBANDINGAN: '/report/laba-rugi/periode',
      MULTI: '/report/laba-rugi/multi',
      KUARTAL: '/report/laba-rugi/kuartal',
      PROJECT: '/report/project-profit-loss/standard'
    },
    CASH_FLOW: {
      STANDARD: '/report/cash-flow/standard'
    },
    CAPITAl_CHARGE: {
      STANDARD: '/report/capital-change/standard'
    },
    SUMMARY: {
      ALL_JURNAL: '/report/buku-besar/jurnal',
      DEBT_CARD: '/report/summary/debt-card',
      DEBT: '/report/summary/debt',
      RECEIVABLE_CARD: '/report/summary/receivables-card',
      RECEIVABLE: '/report/summary/receivables',
      INVENTORY: '/report/summary/inventory',
      DETAIL_PROJECT: '/report/summary/detail-project',
    },
    RATIO:{
      GET: '/report/ratio'
    }
  },
  INTEGRATIONS: {
    LIST_APPLICATION: '/integrations/list-application',
    CHECK: '/integrations/check',
    INIT: '/integrations/set',
    GET: '/integrations/:slug/get',
    REMOVE: '/integrations/remove',
    SYNC_TABLE: '/integrations/sync-database'
  }
};

const URL = {
  DATA_BANK: process.env.VUE_APP_ROOT_API + "/json/bank.json",
};

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const DATA = {
  KARYAWAN: (image = "blank.png") => {
    if (image == null || image == "null") {
      image = "blank.png";
    }
    return `${process.env.VUE_APP_ROOT_DATA}/karyawan/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  REIMBURSE: (image) => {
    return `${process.env.VUE_APP_ROOT_DATA}/reimburse/${image}`;
  },
  HUTANG: (image) => {
    return `${process.env.VUE_APP_ROOT_DATA}/hutang/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  ASET: (image) => {
    return `${process.env.VUE_APP_ROOT_DATA}/aset/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  BARANG: (image) => {
    return `${process.env.VUE_APP_ROOT_DATA}/barang/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  RAB: (image) => {
    return `${process.env.VUE_APP_ROOT_DATA}/rab/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  FORMAT_RAB: () => {
    return `${process.env.VUE_APP_ROOT_DATA}/rab/Format RAB.xlsx`;
  },
  FORMAT_PE: () => {
    return `${process.env.VUE_APP_ROOT_DATA}/rab/Format PE.xlsx`;
  },
  FORMAT_VENDOR_BARANG: () => {
    return `${process.env.VUE_APP_ROOT_DATA}/barang/format_barang_vendor.xlsx`;
  },
  BRAND: (image = '') => {
    return `${process.env.VUE_APP_ROOT_DATA}/brand/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  },
  COMPANY: (image = '') => {
    if (!image) {
      image = 'company.png';
    }
    // return `https://datafinanta.ptabcd.xyz/company/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
    return `${process.env.VUE_APP_ROOT_DATA}/company/${image}?unique=${randomIntFromInterval(100000000, 1000000000000)}`;
  }
};

const ROLE = [
  {
    title: "Company",
    id: "company",
    webAll: false,
    detail: [
      {
        akses: "Melihat dan update data company",
        id: "ru_company",
        web: false,
      },
      {
        akses: "Membuat, melihat dan update data departemen",
        id: "crud_departement",
        web: false,
      },
      {
        akses: "Menambah dan update data sister company",
        id: "cu_sister_company",
        web: false,
      },

      {
        akses: "Menambah dan update data gudang",
        id: "cu_werehouse",
        web: false,
      },
      {
        akses: "Menambah dan update status karyawan",
        id: "cu_employee",
        web: false,
      },
      {
        akses: "Menambah dan update data jabatan",
        id: "cu_position",
        web: false,
      },
    ],
  },
  {
    title: "Client",
    id: "client",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data client",
        id: "cru_client",
        web: false,
      },
      {
        akses: "Menambah dan update kategori client",
        id: "cru_client_category",
        web: false,
      },
      {
        akses: "Melihat client",
        id: "r_client",
        mobile: false,
      },
    ],
  },

  {
    title: "Vendor",
    id: "vendor",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data vendor",
        id: "cru_vendor",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update kategori vendor",
        id: "cru_vendor_category",
        web: false,
        // mobile: false,
      },
      {
        akses: "Melihat data vendor",
        id: "r_vendor",
        // web: false,
        mobile: false,
      },
    ],
  },

  {
    title: "Pengaturan",
    id: "setting",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update item penomoran",
        id: "ru_item_numbering",
        web: false,
      },
      {
        akses: "Menambah dan update Item penggajian",
        id: "cru_item_payroll",
        web: false,
      },
      {
        akses: "Pengaturan sinkronisasi sistem",
        id: "sync",
        web: false,
      },
    ],
  },

  {
    title: "Karyawan",
    id: "employee",
    webAll: false,
    // mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data karyawan",
        id: "cru_employee",
        web: false,
        // mobile: false,
      },
      {
        akses: "Mengatur Hak akses karyawan",
        id: "cu_access_due",
        web: false,
        // mobile: false,
      },
    ],
  },

  {
    title: "Project",
    id: "project",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update detail data project",
        id: "cru_project",
        web: false,
        mobile: false,
      },
      {
        akses: "Menambah dan update RAB",
        id: "cru_rab",
        web: false,
        mobile: false,
      },
      {
        akses: "RAB Approval",
        id: "cru_rab_apporval",
        web: false,
        mobile: false,
      },
      {
        akses: "Menambah dan update team project",
        id: "cru_rab_team",
        web: false,
        mobile: false,
      },
      {
        akses: "Menambah dan update pengeluran project",
        id: "cru_spending",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update Invoice & Pembayaran",
        id: "cru_invoice",
        web: false,
        // mobile: false,
      },
      {
        akses: "Melihat laporan project",
        id: "cru_report_project",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update level di project",
        id: "cru_level",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update kategori project",
        id: "cru_kategori",
        web: false,
        // mobile: false,
      },
    ],
  },

  {
    title: "Akun Perkiraan",
    id: "ledger",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update Akun Perkiraan",
        id: "cru_acoount_estimates",
        web: false,
      },
    ],
  },

  {
    title: "Jurnal",
    id: "kas_bank",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update Pemasukan",
        id: "cru_income",
        web: false,
      },
      {
        akses: "Menambah dan update Pengeluaran",
        id: "cru_credit",
        web: false,
      },
      {
        akses: "Menambah dan update Jurnal Umum",
        id: "cru_jurnal_general",
        web: false,
      },
      {
        akses: "Persetujuan perubahan Pemasukan",
        id: "agree_income",
        web: false,
      },
      {
        akses: "Persetujuan perubahan Pengeluaran",
        id: "agree_credit",
        web: false,
      },
      {
        akses: "Persetujuan perubahan Jurnal Umum",
        id: "agree_jurnal_general",
        web: false,
      },
      {
        akses: "Menambah dan update Transfer Kas & Bank",
        id: "cru_transfer_kasBank",
        web: false,
      },
      {
        akses: "Persetujuan perubahan Transfer Kas & Bank",
        id: "agree_transfer_kasBank",
        web: false,
      },
      {
        akses: "Mengupdate & Melihat Rekonsiliasi Bank",
        id: "cru_recontiliation_bank",
        web: false,
      },
    ],
  },

  {
    title: "Piutang Usaha",
    id: "receivable",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update piutang usaha",
        id: "cru_receivable",
        web: false,
      },
      {
        akses: "Menambah dan update invoice & pembayaran piutang usaha",
        id: "cru_invoice_receivable",
        web: false,
      },
    ],
  },

  {
    title: "Hutang Usaha",
    id: "payable",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update hutang usaha",
        id: "cru_payable",
        web: false,
      },
      {
        akses: "Menambah dan update pembayaran hutang usaha",
        id: "cru_invoice_payable",
        web: false,
      },
    ],
  },

  {
    title: "Aset",
    id: "asset",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update detail data aset",
        id: "cru_asset",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah data transfer lokasi aset",
        id: "cru_location_asset",
        web: false,
        mobile: false,
      },

      {
        akses: "Menambah dan update detail data kategori aset",
        id: "cru_category_asset",
        web: false,
        // mobile: false,
      },

      {
        akses: "Menambah dan update detail data pengecekan aset",
        id: "cru_check_asset",
        web: false,
        // mobile: false,
      },
    ],
  },

  {
    title: "Inventory",
    id: "inventory",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data barang",
        id: "cru_goods",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update kategori barang",
        id: "cru_category_goods",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update penerimaan barang",
        id: "cru_reception_goods",
        web: false,
        mobile: false,
      },
      {
        akses: "Menambah dan update data PO",
        id: "cru_purchase_order",
        web: false,
        mobile: false,
      },
      {
        akses: "PO Approval",
        id: "cru_approval_po",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update perintah dan hasil stock opname",
        id: "cru_stock_opname",
        web: false,
        mobile: false,
      },
      {
        akses: "Pengeluaran barang",
        id: "c_goods_out",
        web: false,
        mobile: false,
      },
      {
        akses: "Menyetujui hasil stock opname",
        id: "approval_stock_opname",
        web: false,
      },
    ],
  },

  {
    title: "Persediaan",
    id: "persediaan",
    webAll: false,
    mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data barang",
        id: "cru_goods",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update kategori barang",
        id: "cru_category_goods",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update penerimaan barang",
        id: "cru_reception_goods",
        web: false,
        mobile: false,
      },
      {
        akses: "Menambah dan update data PO",
        id: "cru_purchase_order",
        web: false,
        mobile: false,
      },
      {
        akses: "PO Approval",
        id: "cru_approval_po",
        web: false,
        // mobile: false,
      },
      {
        akses: "Menambah dan update perintah dan hasil stock opname",
        id: "cru_stock_opname",
        web: false,
        mobile: false,
      },
      {
        akses: "Pengeluaran barang",
        id: "c_goods_out",
        web: false,
        mobile: false,
      },
      {
        akses: "Menyetujui hasil stock opname",
        id: "approval_stock_opname",
        web: false,
      },
    ],
  },

  {
    title: "Kasbon Karyawan",
    id: "kasbon",
    webAll: false,
    // mobileAll: false,
    detail: [
      {
        akses: "Kasbon Karyawan",
        id: "cru_kasbon",
        web: false,
        // mobile: false,
      },
    ],
  },

  {
    title: "Reimburse",
    id: "reimburse",
    webAll: false,
    // mobileAll: false,
    detail: [
      {
        akses: "Menambah dan update data reimburse",
        id: "cru_reimburse",
        web: false,
        // mobile: false,
      },
    ],
  },

  {
    title: "Payroll",
    id: "payroll",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update Payroll",
        id: "cru_payroll",
        web: false,
      },
    ],
  },

  {
    title: "Laporan",
    id: "report",
    webAll: false,
    detail: [
      {
        akses: "Melihat laporan keuangan company",
        id: "r_report_company",
        web: false,
      },
      {
        akses: "Melihat laporan keuangan departemen",
        id: "r_report_departemen",
        web: false,
      },
      {
        akses: "Melihat laporan keuangan sister company",
        id: "r_report_sistercompany",
        web: false,
      },
      {
        akses: "Melihat laporan keuangan konsolidasi",
        id: "r_report_consolidation",
        web: false,
      },
    ],
  },

  {
    title: "Payment Order",
    id: "payment_order",
    webAll: false,
    detail: [
      {
        akses: "Menambah dan update payment order",
        id: "cru_payment_order",
        web: false,
      },
      {
        akses: "Menyetujui data payment order",
        id: "cru_approval_payment_order",
        web: false,
      },
      {
        akses: "Menambah dan update pembayaran payment order",
        id: "cru_invoice_payment_order",
        web: false,
      },
    ],
  },

  {
    title: "Log Aktifitas",
    id: "log_activity",
    webAll: false,
    detail: [
      {
        akses: "Bisa melihat semua aktifitas karyawan",
        id: "r_log_activity",
        web: false,
      },
    ],
  },
];

const ROLE_INDEX = {
  company: 0,
  client: 1,
  vendor: 2,
  setting: 3,
  employee: 4,
  project: 5,
  ledger: 6,
  kas_bank: 7,
  receivable: 8,
  payable: 9,
  asset: 10,
  inventory: 11,
  persediaan: 12,
  kasbon: 13,
  reimburse: 14,
  payroll: 15,
  report: 16,
  payment_order: 17,
  log_activity: 18
};

export { API, DATA, ROLE, ROLE_INDEX, URL };

