<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
// import SidebarDashboard from '../src/components/Sidebar.vue'
// import NavbarDashboard from '../src/components/Navbar.vue'
import { loadScript } from "vue-plugin-load-script";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

export default {
  name: "DashboardLayout",
  components: {},

  // mounted() {
  //     let recaptchaScript = document.createElement('script')
  //     let recaptchaScript2 = document.createElement('script')
  //     let recaptchaScript3 = document.createElement('script')
  //     recaptchaScript.setAttribute('src', '../assets/js/page/index.js')
  //     recaptchaScript2.setAttribute('src', '../assets/js/scripts.js')
  //     recaptchaScript3.setAttribute('src', './assets/js/custom.js')
  //     document.head.appendChild(recaptchaScript)
  //     document.head.appendChild(recaptchaScript2)
  //     document.head.appendChild(recaptchaScript3)
  // },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import './assets/css/style.css'; */

#app {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dcdcdc !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dcdcdc !important;
}

:root {
  --primary: #61efe7;
  --text: #263238;
  --text-light: #909090;
}

/* show border around full height container */

.btn.btn-outline-dark {
  border: 2px solid #32cfb0;
  color: white;
  font-weight: normal;
  border-radius: 0;
}

button:focus,
button:active {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel {
  border: #ff3d3d 1px solid !important;
  color: #ff3d3d !important;
  padding: 0.625em 3.0999999999999996em;
}

.swal2-styled.swal2-confirm {
  border: #2eaea7 1px solid !important;
  color: #2eaea7 !important;
  padding: 0.625em 3.0999999999999996em;
}

.swal2-styled.swal2-cancel:hover {
  background-color: #ff3d3d !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #2eaea7 !important;
  color: #fff !important;
}

.btn.btn-outline-dark:hover,
.btn.btn-outline-dark:active,
.btn.btn-outline-dark:focus,
.btn.btn-outline-dark:visited {
  background-color: #222222;
  border-color: #222222;
  outline: unset;
}

.btn-success-custom {
  background: #32cfb0;
  color: white;
  font-size: 13px;
  padding: 10px 26px;
  border-radius: 0;
}

.btn-success-custom:hover:not(:disabled),
.btn-success-custom:focus:not(:disabled),
.btn-success-custom:active:not(:disabled) {
  background-color: #222222;
  color: white;
}

.btn-success-custom:disabled,
.btn-success-custom:disabled:hover {
  background: #636363 !important;
  color: white;
}

/* Select2 */

.select2 {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  height: 42px !important;
  border-color: #eceff1 !important;
}

.select2-container .select2-selection--multiple {
  min-height: 42px !important;
  border-color: #eceff1 !important;
}

.select2-rekening .select2-selection--single,
.select2-rekening .select2-container .select2-selection--multiple {
  height: 65px !important;
}

.select2-rekening .select2-container--default .select2-selection--single .select2-selection__arrow, 
.select2-rekening .select2-container .select2-selection--multiple .select2-selection__arrow {
  top: 20px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow,
.select2-container .select2-selection--multiple .select2-selection__arrow {
  top: 8px !important;
  right: 8px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-top: 6px !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-top: 0px !important;
  padding-left: 10px !important;
  display: flex !important;;
  flex-wrap: wrap;
  position: relative;
  padding-right: -15px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__clear{
  position: absolute;
  top: 0;
  right: -3px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder,
.select2-container
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #dcdcdc !important;
  font-size: 14px !important;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  margin-bottom: 5px !important;
  background-color: #68caf8 !important;
  color: white !important;
  border-color: #68caf8 !important;
}

.form-error {
  display: block;
  font-size: 14px;
  font-style: italic;
  color: #ff3d3d;
  margin-top: 5px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}
.is-invalid span.select2-selection.select2-selection--single, .is-invalid span.select2-selection.select2-selection--multiple {
  border-color: #dc3545 !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  background: #d90000ba;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  height: 20px;
  color: white;
  margin-top: 5px;
  margin-right: 4px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-invalid.dp__main .dp__input_wrap input {
  border-color: #dc3545 !important;
}

.is-invalid.dp__main .dp__input_wrap svg {
  border-color: #dc3545 !important;
  border-width: 0 1px 0 0;
}

.is-invalid.dp__main .dp__input_wrap input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
