import { create } from 'apisauce';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ROLE_INDEX } from './constans/Url';
// import Cookies from 'js-cookie';

const cksClient = () => {
    var cookies = new Cookies()

    return cookies
}

const apiClient = (withBearer = true, type = 'apisauce') => {
    const _url = process.env.VUE_APP_ROOT_API
    // const _url = 'https://apifinanta.ptabcd.xyz';
    const _tkn = cksClient().get('_authToken')
    const _PeriodYer = cksClient().get('_PeriodYer');
    const options = {
        baseURL: _url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 600000,
    }

    if (withBearer) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${_tkn}`
        }
    }

    if (_PeriodYer) {
        options.headers = {
            ...options.headers,
            periodeactive: _PeriodYer
        }
    }

    const client = axios.create(options)
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError)
        }
    )

    client.interceptors.response.use(
        response => Promise.resolve(response),
        error => {
            if (error.response) {
                const {status} = error.response
                if (status === 403) {
                    console.log('Global api check - Forbidden Access')
                }
            }

            return Promise.reject(error)
        }
    )

    if (type == "apisauce") {
        return create({axiosInstance: client})
    }

    return client
}

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

const checkRules = async (rules, data) => {
    const hasil = {
        success: false,
        error: [],
    }
    var checkRules = true
    for (const key in rules) {
        const datarules = rules[key]
        var value = ((datarules.max || datarules.max <= 0) || datarules.min) ? parseInt(data[key].toString().split('.').join('')) : data[key]
        if (datarules.isPercent) {
            value = parseFloat(data[key]);
        }
        const rulesMax = (datarules.max || datarules.max <= 0) ? parseInt(datarules.max.toString().split('.').join('')) : ''
        if (datarules.changed) {
            hasil.error[key] = ''
            if (datarules.required && !value && !datarules.isArray) {
                checkRules = false
                hasil.error[key] = (datarules.msg) ? datarules.msg : `Inputan ini harus anda isi`;
            } 


            if(datarules.required && datarules.isArray && value.length <= 0) {
                hasil.error[key] = (datarules.msg) ? datarules.msg : `Inputan ini harus anda isi`;
                checkRules = false
            }
    
            if (value && datarules.email && !validateEmail(value)) {
                checkRules = false
                hasil.error[key] = (datarules.msg_email) ? datarules.msg_email : `Anda memasukkan alamat email yang tidak valid`;
            }
            if (datarules.minLength && value.length < datarules.minLength){
                checkRules = false
                hasil.error[key] = (datarules.msg_minLength) ? datarules.msg_minLength : `minimal length 6 character`;
            }
    
            if (datarules.equalTo && value !== data[datarules.equalTo]){
                checkRules = false
                hasil.error[key] = (datarules.msg_equal) ? datarules.msg_equal : `Inputan tidak sama`;
            }
            if (datarules.min != undefined && datarules.min !== "" && (datarules.min > parseInt(value) || isNaN(parseInt(value)) )){
                checkRules = false
                hasil.error[key] = (datarules.msg_min) ? datarules.msg_min : `nilainya harus lebih besar atau sama dengan ${datarules.min}`;
            }

            if (rulesMax != undefined && rulesMax !== "" && (rulesMax < parseInt(value) || isNaN(parseInt(value)) )){
                checkRules = false
                hasil.error[key] = (datarules.msg_ax) ? datarules.msg_ax : `nilainya harus lebih kecil atau sama dengan ${datarules.max}`;
            }

            if (datarules.custom) {
                var check = datarules.custom(value, data)
                if (check != '') {
                  checkRules = false
                  hasil.error[key] = check;
                }
              }

        } else if (datarules) {
            checkRules = false
        }
    }

    hasil.success = checkRules
    return hasil
}

const showAlert = ($swal,data = {
    title: '',
    msg: '',
    showCancelButton: false,
    showConfirmButton: true,
    showLoading: false,
    confirmButtonColor: '#fff',
    confirmButtonText: 'Tutup',
    cancelButtonText: 'Tutup',
    cancelButtonColor: '#fff',
    onCancel: () => {

    },
    onSubmit: () => {

    },
    onDismiss: () => {
        
    }
}) => {
    var option = {
        title: '',
        msg: '',
        showCancelButton: false,
        showConfirmButton: true,
        showLoading: false,
        confirmButtonColor: '#fff',
        confirmButtonText: 'Tutup',
        cancelButtonText: 'Tutup',
        cancelButtonColor: '#fff',
        onCancel: () => {
    
        },
        onSubmit: () => {
    
        },
        onDismiss: () => {

        }
    }
    data = {...option,...data}
    var _msg = (data.msg) ? data.msg : 'Kode otp terkirim, mohon cek kotak masuk'
    // Use swtalert2
    // Kode OTP terkirim ke nomor telepon <span style="color:#0E867F"> 0822******09 </span> mohon cek kotak masuk 
    $swal({
        html: '<div style="color:#222222; font-size:20px; font-weight:500;" class="mt-3 mb-3">'+data.title+'</div>' +
            '<div style="color:#263238" font-size:12px;">'+_msg+'</div>',
        showCancelButton: data.showCancelButton,
        showConfirmButton: data.showConfirmButton,
        confirmButtonColor: data.confirmButtonColor,
        confirmButtonText: data.confirmButtonText,
        cancelButtonColor: data.cancelButtonColor,
        cancelButtonText: data.cancelButtonText,
    }).then(function ({isConfirmed, isDismissed}) {
        if (isConfirmed && typeof data.onSubmit === "function") {
            data.onSubmit()
        } else if (isDismissed && typeof data.onCancel === "function") {
            data.onCancel()
        } else {
            data.onDismiss()
        }
        // window.location = "/verifikasi";
    });
}

const checkModuleAccess = (id_module, id_detail = '') => {
    // var _hakaccessRaw = cksClient().get('_access')
    var _hakaccessRaw = JSON.parse(localStorage.getItem("_access"))

    var _hakaccess = true

    if(!_hakaccessRaw || _hakaccessRaw.length == 0) {
        _hakaccessRaw = []
    }

    var isGranted = false
    var indexModule = ROLE_INDEX[id_module]
    var _accessList = _hakaccessRaw[indexModule]
    isGranted = (_accessList && (_accessList.all || _accessList.all == 'true') && _accessList.all != 'false' ) ? true : false
    if (_hakaccess && !isGranted && _accessList) {
        for (const key in _accessList.detail) {
            if (Object.hasOwnProperty.call(_accessList.detail, key)) {
                const element = _accessList.detail[key];
                if (element.id == id_detail) {
                    isGranted = ((element.web || element.web == 'true') && element.web != 'false' ) ? true : false
                    if (isGranted) {
                        break
                    }
                }
            }
        }
    }

    return isGranted
}

const showLoading = ($swal) => {
    var swalObj = $swal({
      html: `
        <span class="fas fa-sync-alt fa-spin" style="
    color: #0082FE;
    font-size: 4rem;
    padding: 40px;
  "></span>
        <h6 style="
    color: #909090;
    line-height: 25px;
    font-size: 20px;
  ">Sinkronisasi sedang berlangsung <br>harap tunggu beberapa menit</h6>
      `,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    return swalObj
  }

const checkApp = (application) => {
    const listApplication = {
        rawuh: 'appRawuh',
        finanta: 'appFinanta',
    }
    // var isExist = false;
    var checkApp = cksClient().get('_account').checkSync[listApplication[application]]
    return checkApp ? checkApp : false;
}

const checkFileOrImageFromLink = (link) => {
    var fileExtension = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
    var split = link.toString().split('.');

    if (fileExtension.includes(split[split.length - 1])) {
        return 'image';
    }

    return 'file';
}

export {
    apiClient, checkApp,
    checkFileOrImageFromLink, checkModuleAccess, checkRules, cksClient, showAlert, showLoading
};

